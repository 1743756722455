<template>
  <b-card class="request-form">
    <template #header>
      <div class="card-header">
        {{ title }}
      </div>
    </template>
    <div>
      <b-form>
        <b-form-group>
          <b-row v-if="isEmailAssign == 'true'">
            <template v-for="key in Object.keys(requestData)">
              <b-col
                v-if="
                  key != 'city' &&
                    key != 'zip_code' &&
                    key != 'country' &&
                    key != 'date' &&
                    key != 'email' &&
                    key != 'ready_time' &&
                    key != 'close_time'
                "
                :key="key"
                sm="12"
                md="6"
                lg="4"
              >
                <b-form-input
                  type="text"
                  class="request-section__input"
                  size="sm"
                  :label="key"
                  :placeholder="key | removeUnderscores"
                  :value="requestData[key]"
                  @blur="updateValue(key, $event.target.value)"
                  :disabled="!editable"
                />
              </b-col>
              <b-col
                v-if="key == 'close_time' || key == 'ready_time'"
                :key="key"
                sm="12"
                lg="4"
              >
                <b-form-input
                  v-if="
                    (title != 'Delivery Request Info' && clientId != '3') ||
                      clientId == '3' ||
                      clientId == '5'
                  "
                  type="time"
                  :placeholder="
                    key == 'close_time' ? 'Close Time' : 'Ready Time'
                  "
                  v-model="value[key]"
                  size="sm"
                  class="request-section__input"
                  :disabled="!editable"
                  @input="updateValue(key, $event)"
                >
                </b-form-input>
              </b-col>
              <b-col v-if="key == 'email'" :key="key" sm="12" lg="8">
                <b-form-input
                  class="request-section__input"
                  v-model="email"
                  size="sm"
                  :state="emailFieldState"
                  :label="key"
                  :placeholder="key | removeUnderscores"
                  :value="requestData[key]"
                  @blur="updateValue(key, $event.target.value)"
                  :disabled="!editable"
                />
              </b-col>
              <b-col :key="key" sm="12" md="6" lg="4" v-if="key == 'country'">
                <b-form-input
                  placeholder="Country"
                  class="request-section__input"
                  size="sm"
                  @click="cleanCountry"
                  @input.native="updateValue(key, $event.target.value)"
                  v-model="country"
                  list="my-list-id"
                  autocomplete="off"
                  :disabled="!editable"
                ></b-form-input>
                <datalist id="my-list-id">
                  <option
                    :key="key + index"
                    v-for="(country, index) in options"
                  >
                    {{ country }}
                  </option>
                </datalist>
              </b-col>
              <b-col
                :key="key"
                v-if="
                  (key == 'date' &&
                    title != 'Delivery Request Info' &&
                    clientId != '3') ||
                    (key == 'date' && clientId == '3') ||
                    (key == 'date' && clientId == '5')
                "
                class="col-sm-4"
              >
                <b-form-input
                  type="date"
                  v-model="date"
                  size="sm"
                  class="mb-2 request-section__datepicker"
                  :disabled="!editable"
                  @input="updateValue(key, $event)"
                >
                </b-form-input>
              </b-col>
            </template>
          </b-row>
          <b-row v-if="isEmailAssign == 'false'">
            <template v-for="key in Object.keys(requestData)">
              <b-col :key="key" sm="12" md="6" lg="4">
                <b-form-input
                  type="text"
                  class="request-section__input"
                  :placeholder="key | removeUnderscores"
                  size="sm"
                  :value="requestData[key]"
                  :label="key"
                  :disabled="!editable"
                />
              </b-col>
            </template>
          </b-row>
          <b-row
            v-if="
              title == 'Delivery Request Info' &&
                clientId != '3' &&
                clientId != '5'
            "
            style="height: 46px"
          >
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "RequestForm",
  props: ["value", "title", "trip", "editable", "mapCountry"],
  computed: {
    ...mapGetters("emailDetails", [
      "pickupRequestByTrip",
      "deliveryRequestByTrip"
    ])
  },
  data() {
    return {
      hour: "",
      requestData: this.value,
      emailFieldState: null,
      country: this.value.country,
      date: this.value.date,
      options: ["Canada", "Mexico", "USA"],
      email: this.value.email,
      isEmailAssign: null,
      clientId: null
    };
  },
  watch: {
    mapCountry() {
      this.requestData["country"] = this.mapCountry;
      this.country = this.mapCountry;
    }
  },
  created() {
    this.currentDay();
    this.isEmailAssign = localStorage.getItem("isEmailAssign");
    this.clientId = localStorage.getItem("client_id");
  },
  methods: {
    ...mapMutations("emailDetails", ["setPickupRequest, setDeliveryRequest"]),
    updateValue(key, value) {
      if (key === "email") {
        this.emailKey(key, value);
      } else if (key === "date") {
        this.dateKey(key, value);
      } else if (
        key !== "phone" &&
        key !== "reference" &&
        key !== "ready_time" &&
        key !== "close_time" &&
        value !== ""
      ) {
        this.requestData[key] = this.filter(value);
        this.$emit("input", this.requestData);
      } else {
        this.requestData[key] = value;
        this.$emit("input", this.requestData);
      }
    },
    filter(value) {
      return value
        .trim()
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    cleanCountry() {
      this.country = "";
    },
    currentDay() {
      let cliendId = localStorage.getItem("client_id");
      if (cliendId !== "3") {
        let currentDay = new Date();
        this.date =
          currentDay.getFullYear() +
          "-" +
          "0" +
          (currentDay.getMonth() + 1) +
          "-" +
          currentDay
            .getDate()
            .toString()
            .padStart(2, "0");
        this.requestData["date"] === ""
          ? (this.requestData["date"] = this.date)
          : "";
      }
    },
    emailKey(key, value) {
      if (this.email.length > 0) {
        this.requestData[key] = value;
        this.$emit("input", this.requestData);
        this.emailFieldState = true;
      } else {
        this.emailFieldState = null;
        this.requestData[key] = "";
      }
    },
    dateKey(key, value) {
      let year, month, day;
      [year, month, day] = value.split("-");
      let auxDate = `${month}/${day}/${year}`;
      this.requestData[key] = auxDate;
      this.$emit("input", this.requestData);
    }
  }
};
</script>

<style scoped>
.request-form {
  border-radius: 10px;
  transition: transform 0.1s;
  margin: 10px;
}
.request-section__input {
  margin: 5px;
  border: 1px solid #a3a3a3;
  border-radius: 3px;
  color: #6d6868;
  background: #fafafa;
}
.request-section__select {
  margin-top: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 7px;
}
.request-section__date {
  margin-top: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 7px;
}
::v-deep .form-control {
  display: flex;
  margin-top: 7px;
  margin-left: 1px;
}
::v-deep .text-muted {
  margin: 0px;
}
::v-deep .dropdown-menu.show {
  display: grid;
}
.card-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--subtitle-color);
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  padding: 0.5rem;
}

input[type="checkbox"]:checked:focus,
.form-control:focus {
  border-color: var(--button-hover-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 5px var(--button-hover-color);
}
</style>

<!-- <b-form-timepicker
                :placeholder="key == 'close_time' ? 'Close Time' : 'Ready Time'"
                col-xl
                class="request-section__input"
                size="sm"
                :disabled="!editable"
                :hour12="false"
                :minutes-step="5"
                @input="updateValue(key, $event)"
                locale="en"
              ></b-form-timepicker> -->
